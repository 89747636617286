/* You can add global styles to this file, and also import other style files */
:root {
  font-size: 16px;
}

@import "assets/scss/custom-theme";
@import "assets/scss/custom-classes";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
